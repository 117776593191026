import { createRouter, createWebHashHistory } from "vue-router";
import Index from '@/views/index.vue';
import About from '@/views/about.vue';
import Products from '@/views/products.vue';
import Card from '@/views/card.vue';
import Contact from '@/views/contact.vue';

const routes = [
	{
		path:'/',
		component:Index
	},
	{
		path:'/about',
		component:About,
		children:[
			{
				path:'intro',
				component:()=>import("@/views/about/intro")
			},
			{
				path:'show',
				component:()=>import("@/views/about/show")
			},
			{
				path:'target',
				component:()=>import("@/views/about/target")
			},
			{
				path:'partner',
				component:()=>import("@/views/about/partner")
			}
		]
	},
	{
		path:'/products',
		component:Products,
		children:[
			{
				path:'food',
				component:()=>import("@/views/products/food")
			},
			{
				path:'drinks',
				component:()=>import("@/views/products/drinks")
			},
			{
				path:'coffee',
				component:()=>import("@/views/products/coffee")
			},
			{
				path:'snack',
				component:()=>import("@/views/products/snack")
			}
		]
	},
	{
		path:'/card',
		component:Card
	},
	{
		path:'/contact',
		component:Contact
	}
	// {
	// 	path:'/vip',
	// 	component:()=>import("@/views/vip/vip.vue"),
	// 	children:[
	// 		{
	// 			path:'order',
	// 			component:()=>import("@/views/vip/order")
	// 		},
	// 		{
	// 			path:'user',
	// 			component:()=>import("@/views/vip/user")
	// 		}
	// 	]
	// }
]

const router = createRouter({
	history:createWebHashHistory(),
	routes
})

router.beforeEach((to,from,next)=>{
	// console.log(to);
	// console.log(from);
	next();
})

export default router;