<template>
	<nav class="navbar navbar-expand-lg navbar-custom fixed-top" data-bs-theme="dark">
	  <div class="container-fluid navbarContent">
		<router-link to="/" class="navbar-brand">
			<img class="img" src="@/assets/index/logo.png" alt="logo" />
		</router-link>
		<!-- 移动端menuIcon -->
	    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
	      <span class="navbar-toggler-icon"></span>
	    </button>
		<!-- 移动端menuIcon end -->
		
	    <div class="collapse navbar-collapse" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" style="justify-content:flex-end;" id="navbarSupportedContent">
	      <ul class="navbar-nav">
	        <li class="nav-item">
				<router-link to="/">
					<div>首页</div>
					<div>HOME</div>
				</router-link>
	        </li>
			<li class="nav-item">
				<router-link to="/about">
					<div>关于我们</div>
					<div>ABOUT</div> 
				</router-link>
			</li>
			<li class="nav-item">
				<router-link to="/products">
					<div>产品介绍</div>
					<div>PRODUCTS</div>
				</router-link>
			</li>
			<li class="nav-item">
				<router-link to="/card">
					<div>一卡通</div>
					<div>CARD</div>
				</router-link>
			</li>
			<li class="nav-item">
				<router-link to="/contact">
					<div>联系我们</div>
					<div>CONTACT</div>
				</router-link>
			</li>
	      </ul>
	    </div>
	  </div>
	</nav>
</template>

<script>
</script>

<style lang="scss" scoped>
.navbar-custom{
	background-color: #000;
}
.navbar{
	.navbarContent{
		margin: auto 100px;
		a {
			text-decoration: none;
			color: #999;
		}
		 
		.router-link-active {
			color: #FEF200;
		}
		.navbar-brand{
			height: 50px;
			.img{
				height: 40px;
			}
		}
		
		.navbar-nav{
			text-align:center;
			.nav-item{
				margin:0 30px;
				font-size:14px;
			}
		}
	}
	
}


@media (max-width: 575px){
	.navbar{
		.navbarContent{
			margin: auto 20px;
			.navbar-brand{
				height: 40px;
				.img{
					height: 30px;
				}
			}
			
			.navbar-nav{
				text-align:left;
				.nav-item{
					margin:10px 30px;
				}
			}
		}
		
	}
	
}
</style>